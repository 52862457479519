
import { defineComponent, shallowRef, PropType } from 'vue'

// Components
import PanelSection from '@/components/PanelSection.vue'
import UnitSelect from '@/metrics/UnitSelect.vue'

// Misc
import { aggFuncItems, TableColumn } from '@/metrics/types'
import { requiredRule } from '@/util/validation'

export default defineComponent({
  name: 'TableColumnOptionsForm',
  components: { PanelSection, UnitSelect },

  props: {
    column: {
      type: Object as PropType<TableColumn>,
      required: true,
    },
  },

  setup(props) {
    const dialog = shallowRef(false)
    const rules = { aggFunc: [requiredRule] }
    return { dialog, rules, aggFuncItems }
  },
})
