
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useSnackbar } from '@/use/snackbar'
import { useGridItemManager } from '@/metrics/use-dashboards'

// Components
import ForceReloadBtn from '@/components/ForceReloadBtn.vue'

// Misc
import { ChartGridItem } from '@/metrics/types'

export default defineComponent({
  name: 'GridItemFormDialog',
  components: {
    Splitpanes,
    Pane,
    ForceReloadBtn,
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    gridItem: {
      type: Object as PropType<ChartGridItem>,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '1400px',
    },
  },

  setup(props, ctx) {
    const snackbar = useSnackbar()

    const form = shallowRef()
    const isValid = shallowRef(false)

    const gridItemMan = useGridItemManager()
    function submit() {
      if (!form.value.validate()) {
        return
      }
      if ('metrics' in props.gridItem.params && !props.gridItem.params.metrics.length) {
        snackbar.notifyError(`Please select a metric and click the "Apply" button`)
        return
      }
      gridItemMan.save(props.gridItem).then((gridItem) => {
        ctx.emit('save', gridItem)
      })
    }

    return {
      form,
      isValid,

      gridItemMan,
      submit,
    }
  },
})
