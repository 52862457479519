
import { defineComponent, shallowRef, computed, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { formatGauge, useGaugeQuery } from '@/metrics/use-gauges'

// Components
import GridItemFormPanes from '@/metrics/GridItemFormPanes.vue'
import SinglePanel from '@/components/SinglePanel.vue'
import PanelSection from '@/components/PanelSection.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import GaugeCard from '@/metrics/GaugeCard.vue'
import GaugeValuesTable from '@/metrics/GaugeValuesTable.vue'
import GaugeColumnOptionsForm from '@/metrics/GaugeColumnOptionsForm.vue'
import ValueMappingsForm from '@/metrics/ValueMappingsForm.vue'

// Misc
import { requiredRule, minMaxStringLengthRule } from '@/util/validation'
import { updateColumnMap, emptyGaugeColumn, GaugeGridItem } from '@/metrics/types'

export default defineComponent({
  name: 'GridItemGaugeForm',
  components: {
    GridItemFormPanes,
    SinglePanel,
    PanelSection,
    MetricsPicker,
    MetricsQueryBuilder,
    GaugeCard,
    GaugeValuesTable,
    GaugeColumnOptionsForm,
    ValueMappingsForm,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    tableGrouping: {
      type: Array as PropType<string[]>,
      required: true,
    },
    gridItem: {
      type: Object as PropType<GaugeGridItem>,
      required: true,
    },
  },

  setup(props, ctx) {
    const mappingsDialog = shallowRef(false)
    const rules = { title: [requiredRule, minMaxStringLengthRule(0, 40)] }

    const uql = useUql()
    const activeMetrics = useActiveMetrics(computed(() => props.gridItem.params.metrics))

    const gaugeQuery = useGaugeQuery(
      () => {
        if (
          !props.gridItem ||
          !props.gridItem.params.metrics.length ||
          !props.gridItem.params.query
        ) {
          return { _: undefined }
        }

        return {
          ...props.dateRange.axiosParams(),
          metric: props.gridItem.params.metrics.map((m) => m.name),
          alias: props.gridItem.params.metrics.map((m) => m.alias),
          query: props.gridItem.params.query,
        }
      },
      computed(() => props.gridItem.params.columnMap),
    )

    const gaugeText = computed(() => {
      return formatGauge(
        gaugeQuery.values,
        gaugeQuery.styledColumns,
        props.gridItem.params.template,
        'Add a metric first...',
      )
    })

    watch(
      () => props.gridItem.params.query,
      (query) => {
        uql.query = query
      },
      { immediate: true },
    )
    watch(
      () => uql.query,
      (query) => {
        props.gridItem.params.query = query
      },
    )

    watch(
      () => gaugeQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
    )
    watch(
      () => gaugeQuery.columns,
      (columns) => {
        updateColumnMap(props.gridItem.params.columnMap, columns, emptyGaugeColumn)
      },
    )

    return {
      mappingsDialog,

      uql,

      activeMetrics,
      gaugeQuery,
      gaugeText,

      rules,
    }
  },
})
